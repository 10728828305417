import Layout from 'components/Layout/Layout'
import { LayoutTextSection } from 'components/Layout/LayoutTextSection'
import { Link } from 'components/Link/Link'
import { ParagraphHeading } from 'components/ParagraphHeading/ParagraphHeading'
import SEO from 'components/SEO/SEO'
import {
  COMPANY_OFFICIAL_ADDRESS,
  COMPANY_OFFICIAL_NAME,
  COMPANY_OFFICIAL_TAX_NUMBER_NIP,
  SITE_HOSTNAME,
} from 'constants/texts/company'
import { Text } from 'ui/common'

const CookiesPolicy: React.FC = () => (
  <Layout>
    <SEO title="Polityka Cookies" />

    <LayoutTextSection mt="xl">
      <h1>Polityka Cookies</h1>
      <p style={{ fontStyle: 'italic' }}>
        Polityka plików cookies - &quot;ciasteczek&quot;
      </p>
      <p>
        Poniższa Polityka Cookies określa{' '}
        <strong>
          zasady zapisywania i uzyskiwania dostępu do danych na Urządzeniach
          Użytkowników
        </strong>{' '}
        korzystających z Serwisu do celów świadczenia usług drogą elektroniczną
        przez Administratora Serwisu.
      </p>
      <p>
        W celu zapoznania sie z polityką dotyczącą przetwarzania zebranych przez
        nas danych należy przejść do sekcji{' '}
        <Link to="/polityka-prywatnosci">Polityka Prywatności</Link>.
      </p>

      <ParagraphHeading>Definicje</ParagraphHeading>
      <ul>
        <li>
          <p>
            <strong>Serwis, Glamo.pl</strong> - serwis internetowy działający
            pod adresem <span className="colored bold">{SITE_HOSTNAME}</span>
          </p>
        </li>
        <li>
          <p>
            <strong>Serwis zewnętrzny</strong> - serwisy internetowe partnerów,
            usługodawców lub usługobiorców Administratora
          </p>
        </li>
        <li>
          <p>
            <strong>Administrator</strong> -{' '}
            <span style={{ fontStyle: 'italic' }}>
              (Administrator Serwisu, Administrator Danych Osobowych)
            </span>{' '}
            firma{' '}
            <span>
              <span className="colored bold">{COMPANY_OFFICIAL_NAME}</span>,
              prowadząca działalność pod adresem:{' '}
              <span className="colored bold">{COMPANY_OFFICIAL_ADDRESS}</span>,
              o nadanym numerze identyfikacji podatkowej (NIP):{' '}
              <span className="colored bold">
                {COMPANY_OFFICIAL_TAX_NUMBER_NIP}
              </span>
            </span>
            , świadcząca usługi drogą elektroniczną za pośrednictwem Serwisu
            oraz przechowująca i uzyskująca dostęp do informacji w urządzeniach
            Użytkownika
          </p>
        </li>
        <li>
          <p>
            <strong>Użytkownik</strong> - osoba fizyczna, dla której
            Administrator świadczy usługi drogą elektroniczna za pośrednictwem
            Serwisu.
          </p>
        </li>
        <li>
          <p>
            <strong>Urządzenie</strong> - elektroniczne urządzenie wraz z
            oprogramowaniem, za pośrednictwem, którego Użytkownik uzyskuje
            dostęp do Serwisu
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w
            formie plików zamieszczanych na Urządzeniu Użytkownika
          </p>
        </li>
      </ul>
      <ParagraphHeading>Rodzaje Cookies</ParagraphHeading>
      <p>Podział ze względu na rodzaj Serwisu ustanawiającego ciasteczka:</p>
      <ul>
        <li>
          <p>
            <strong>Cookies wewnętrzne</strong> - pliki zamieszczane i
            odczytywane z Urządzenia Użytkownika przez system teleinformatyczny
            Serwisu
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies zewnętrzne</strong> - pliki zamieszczane i
            odczytywane z Urządzenia Użytkownika przez systemy teleinformatyczne{' '}
            <a href="#external-services">Serwisów zewnętrznych</a>
          </p>
        </li>
      </ul>
      <p>Podział ze względu na okres istnienia ciasteczek:</p>
      <ul>
        <li>
          <p>
            <strong>Cookies sesyjne</strong> (session cookies) - tymczasowe
            pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez
            Serwis <a href="#external-services">lub Serwisy zewnętrzne</a>{' '}
            podczas jednej sesji danego Urządzenia. Po zakończeniu sesji (tj. po
            wylogowaniu, opuszczeniu strony internetowej lub wyłączeniu
            przeglądarki internetowej) pliki są usuwane z Urządzenia
            Użytkownika.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies trwałe</strong> (persistent cookies) - pliki
            zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis{' '}
            <a href="#external-services">lub Serwisy zewnętrzne</a> do momentu
            ich ręcznego usunięcia lub przez czas określony w parametrach plików
            cookies. <br />
            Pliki te nie są usuwane automatycznie po zakończeniu sesji
            Urządzenia, chyba że konfiguracja Urządzenia Użytkownika jest
            ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji
            Urządzenia.
          </p>
        </li>
      </ul>
      <ParagraphHeading>Bezpieczeństwo</ParagraphHeading>
      <ul>
        <li>
          <p>
            <strong>Mechanizmy składowania i odczytu</strong> - Mechanizmy
            składowania i odczytu Cookies nie pozwalają na pobierania
            jakichkolwiek danych osobowych ani żadnych informacji poufnych z
            Urządzenia Użytkownika. Przeniesienie na Urządzenie Użytkownika
            wirusów, koni trojańskich oraz innych robaków jest praktycznie
            niemożliwe.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookie wewnętrzne</strong> - zastosowane przez
            Administratora Cookie wewnętrzne są bezpieczne dla Urządzeń
            Użytkowników
          </p>
        </li>
        <li>
          <p>
            <strong>Cookie zewnętrzne</strong> - za bezpieczeństwo plików Cookie
            pochodzących od partnerów Serwisu Administrator nie ponosi
            odpowiedzialności. Lista partnerów zamieszczona jest w dalszej
            części Polityki Cookie.
          </p>
        </li>
      </ul>
      <ParagraphHeading>
        Cele do których wykorzystywane są pliki Cookie
      </ParagraphHeading>
      <ul>
        <li>
          <p>
            <strong>
              <span className="colored">
                Usprawnienie i ułatwienie dostępu do Serwisu
              </span>
            </strong>{' '}
            - Administrator może przechowywać w plikach Cookie informacje o
            preferencjach i ustawieniach użytkownika dotyczących Serwisu aby
            usprawnić, polepszyć i przyśpieszyć świadczenie usług w ramach
            Serwisu.
          </p>
        </li>
        <li>
          <p>
            <strong>
              <span className="colored">Dane statystyczne</span>
            </strong>{' '}
            - Administrator{' '}
            <a href="#external-services">oraz Serwisy zewnętrzne</a>{' '}
            wykorzystuje pliki Cookie do zbierania i przetwarzania danych
            statystycznych takich jak np. statystyki odwiedzin, statystyki
            Urządzeń Użytkowników czy statystyki zachowań użytkowników. Dane te
            zbierane są w celu analizy i ulepszania Serwisu.
          </p>
        </li>
        <li>
          <p>
            <strong>
              <span className="colored">Serwowanie usług multimedialnych</span>
            </strong>{' '}
            - Administrator{' '}
            <a href="#external-services">oraz Serwisy zewnętrzne</a>{' '}
            wykorzystuje pliki Cookie do serwowania Użytkownikom usług
            multimedialnych.
          </p>
        </li>
      </ul>
      <ParagraphHeading>
        <span id="external-services" />
        Serwisy zewnętrzne
      </ParagraphHeading>
      <p>
        Administrator współpracuje z następującymi serwisami zewnętrznymi i ich
        usługami, które mogą zamieszczać pliki Cookie na Urządzeniach
        Użytkownika:
      </p>
      <ul>
        <li>
          <p>
            <strong>
              <span className="colored">Google Analytics</span>
            </strong>
          </p>
        </li>
        <li>
          <p>
            <strong>
              <span className="colored">Google Tag Manager</span>
            </strong>
          </p>
        </li>
        <li>
          <p>
            <strong>
              <span className="colored">Youtube</span>
            </strong>
          </p>
        </li>
        <li>
          <p>
            <strong>
              <span className="colored">Instagram</span>
            </strong>
          </p>
        </li>
      </ul>
      <ParagraphHeading>
        Możliwości określania warunków przechowywania i uzyskiwania dostępu na
        Urządzeniach Użytkownika przez Serwis{' '}
        <a href="#external-services" className="bold">
          i Serwisy zewnętrzne
        </a>
      </ParagraphHeading>
      <ul>
        <p>
          Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?
        </p>
        <li>
          <p>
            Użytkownik może w dowolnym momencie samodzielnie zmienić ustawienia
            dotyczące zapisywania, usuwania oraz dostępu do danych zapisanych
            plików Cookies poprzez zmianę ustawień przeglądarki.
          </p>
        </li>
        <li>
          <p>
            Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla
            procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji
            użytkownika może utrudnić, a w skrajnych przypadkach może
            uniemożliwić korzystanie ze stron www.
          </p>
        </li>
        <li>
          <p>
            Informacje o sposobie wyłączenia plików Cookie w najpopularniejszych
            przeglądarkach komputerowych i urządzeń mobilnych dostępne są na
            stronie:{' '}
            <a
              href="http://jakwylaczyccookie.pl"
              target="_blank"
              rel="noopener noreferrer"
            >
              jak wyłączyć cookie
            </a>
            .
          </p>
        </li>
        <li>
          <p>
            Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej
            pory pliki Cookie korzystając z narzędzi Urządzenia Użytkownika za
            pośrednictwem którego Użytkownik korzysta z usług Serwisu.
          </p>
        </li>
      </ul>
      <ParagraphHeading>Wyłączenie odpowiedzialności</ParagraphHeading>
      <ul>
        <li>
          Administrator stosuje wszelkie możliwe środki w celu zapewnienia
          bezpieczeństwa danych umieszczanych w plikach Cookie. Należy jednak
          zwrócić uwagę, że zapewnienie bezpieczeństwa tych danych zależy od obu
          stron, w tym działalności Użytkownika oraz stanu zabezpieczeń
          urządzenia z którego korzysta.
        </li>
        <li>
          Administrator nie bierze odpowiedzialności za przechwycenie danych
          zawartych w plikach Cookie, podszycie się pod sesję Użytkownika lub
          ich usunięcie, na skutek świadomej lub nieświadomej działalność
          Użytkownika, wirusów, koni trojańskich i innego oprogramowania
          szpiegującego, którymi może być zainfekowane Urządzenie Użytkownika.
        </li>
        <li>
          Użytkownicy w celu zabezpieczenia się przed wskazanymi w punkcie
          poprzednim zagrożeniami powinni stosować się do{' '}
          <span>
            <a href="https://nety.pl/cyberbezpieczenstwo/">
              zasad cyberbezpieczeństwa
            </a>
          </span>
          .
        </li>
        <li>
          Usługi świadczone przez podmioty trzecie są poza kontrolą
          Administratora. Podmioty te mogą w każdej chwili zmienić swoje warunki
          świadczenia usług, cel oraz wykorzystanie plików cookie. Administrator
          nie odpowiada na tyle, na ile pozwala na to prawo, za działanie plików
          cookies używanych przez serwisy partnerskie. Użytkownicy w każdej
          chwili mogą samodzielnie zarządzać zezwoleniami i ustawieniami plików
          cookie dla każdej dowolnej witryny.
        </li>
      </ul>
      <ParagraphHeading>Wymagania Serwisu</ParagraphHeading>
      <ul>
        <li>
          <p>
            Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu
            Użytkownika może spowodować nieprawidłowe działanie niektórych
            funkcji Serwisu.
          </p>
        </li>
        <li>
          <p>
            Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo
            działające funkcje Serwisu w przypadku gdy Użytkownik ograniczy w
            jakikolwiek sposób możliwość zapisywania i odczytu plików Cookie.
          </p>
        </li>
      </ul>
      <ParagraphHeading>Zmiany w Polityce Cookies</ParagraphHeading>
      <ul>
        <li>
          <p>
            Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej
            Polityki Cookies bez konieczności informowania o tym użytkowników.
          </p>
        </li>
        <li>
          <p>
            Wprowadzone zmiany w Polityce Cookies zawsze będą publikowane na tej
            stronie.
          </p>
        </li>
        <li>
          <p>
            Wprowadzone zmiany wchodzą w życie w dniu publikacji Polityki
            Cookies.
          </p>
        </li>
      </ul>

      <Text my="m">Aktualizacja: 10.01.2022r.</Text>
    </LayoutTextSection>
  </Layout>
)

export default CookiesPolicy
