import styled from 'styled-components'

import { Text } from 'ui/common'

export const ParagraphHeading = styled(Text).attrs({ as: 'h2' })`
  margin-top: 1.5em;
  margin-bottom: 1em;

  &::before {
    counter-increment: section;
    content: counter(section) '. ';
  }
`
